import { useState, useEffect } from "react";
import { useModemActivationSettingHooks } from "../../utils/SettingUtils";
import Box from "../Box";
import AlertBox from "../AlertBox";
import { updateSettingByKey } from "../../api/setting";
import Toggle from "react-toggle";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function BoxSettingModemActivation() {
  let { hasAccess } = useControlPermission();

  let {
    data,
    fetched,
    loading: dataLoading,
    getActivationSetting,
  } = useModemActivationSettingHooks();
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({});
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fetched) {
      let defaultForms = {
        ...data.value,
      };
      setForm(defaultForms);
    }
  }, [fetched, data]);

  const toggleStatus = (value) => {
    let tempForm = { ...form };
    tempForm.status = value;

    setForm(tempForm);
  };

  const changeBrand = (index, value) => {
    let tempForm = { ...form };
    let supported_brands = [...tempForm.supported_brands];

    supported_brands[index] = value;

    tempForm.supported_brands = supported_brands;

    setForm(tempForm);
  };

  const removeBrand = (index) => {
    let tempForm = { ...form };
    let supported_brands = [...tempForm.supported_brands];

    supported_brands.splice(index, 1);

    tempForm.supported_brands = supported_brands;
    setForm(tempForm);
  };

  const addBrand = (index) => {
    let tempForm = { ...form };
    let supported_brands = [...tempForm.supported_brands];

    supported_brands.push("");

    tempForm.supported_brands = supported_brands;
    setForm(tempForm);
  };

  const sendData = async (form) => {
    setLoading(true);
    try {
      let response = await updateSettingByKey("modem-activation-automation", form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      getActivationSetting();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = {};
    formData["modem-activation-automation"] = form;

    sendData(formData);
  };

  return (
    <Box title="Setting Aktivasi Modem" loading={dataLoading || loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <div className="form-group row">
          <div className="col-auto">
            <label>Status Fitur</label>
          </div>
          <div className="col-auto">
            <Toggle
              checked={form.status === "on"}
              onChange={(event) => {
                if (event.target.checked) {
                  toggleStatus("on");
                } else {
                  toggleStatus("off");
                }
              }}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Brand Modem yang mendukung aktivasi otomatis</label>
          {form.supported_brands?.map((brand, i) => (
            <div key={i} className="form-group row">
              <div className="col">
                <input
                  className="form-control"
                  type="text"
                  value={brand}
                  onChange={(event) => changeBrand(i, event.target.value)}
                />
              </div>
              <div className="col-auto">
                <div className="btn btn-secondary btn-circle" onClick={() => removeBrand(i)}>
                  <i className="fa fa-minus"></i>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="form-group mb-2">
          <button type="button" className="btn btn-sm btn-light" onClick={() => addBrand()}>
            <i className="fa fa-plus"></i> Tambah modem yang didukung
          </button>
        </div>
        <div className="form-group d-flex justify-content-end">
          {hasAccess("update-setting") && (
            <button className="btn btn-primary btn-block">Simpan</button>
          )}
        </div>
      </form>
    </Box>
  );
}
