import { useState, useEffect } from "react";
import { useBankMutationSettingHooks } from "../../utils/SettingUtils";
import Box from "../Box";
import AlertBox from "../AlertBox";
import { updateSettingByKey } from "../../api/setting";
import Timepicker from "../Timepicker";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function BoxSettingBankMutation() {
  let { hasAccess } = useControlPermission();

  let {
    data,
    fetched,
    loading: dataLoading,
    getAutoTransferSetting,
  } = useBankMutationSettingHooks();
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({ start_time: "00:00", end_time: "00:00" });
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fetched) {
      let defaultForms = {
        ...data.value,
      };
      setForm(defaultForms);
    }
  }, [fetched, data]);

  const sendData = async (form) => {
    setLoading(true);
    try {
      let response = await updateSettingByKey("auto-transfer", form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      getAutoTransferSetting();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = {};
    formData["auto-transfer"] = form;

    sendData(formData);
  };

  const changeValueForm = (key, value) => {
    let tempForm = { ...form };
    tempForm[key] = value;
    setForm(tempForm);
  };

  return (
    <Box title="Setting Mutasi Otomatis" loading={dataLoading || loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <div className="form-group">
          <label>Waktu cek mutasi BCA otomatis mulai:</label>
          <div className="row">
            <div className="col-sm-4">
              <Timepicker
                time={form.start_time}
                onChangeTime={(value) => changeValueForm("start_time", value)}
              />
            </div>
            <div className="col-auto">Sampai</div>
            <div className="col-sm-4">
              <Timepicker
                time={form.end_time}
                onChangeTime={(value) => changeValueForm("end_time", value)}
              />
            </div>
          </div>
        </div>

        {hasAccess("update-setting") && (
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary">Simpan Perubahan</button>
          </div>
        )}
      </form>
    </Box>
  );
}
