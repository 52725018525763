import { useState, useEffect } from "react";
import { useMrtgGraphSettingHooks } from "../../utils/SettingUtils";
import Box from "../Box";
import AlertBox from "../AlertBox";
import { updateSettingByKey } from "../../api/setting";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function BoxSettingMrtg() {
  let { hasAccess } = useControlPermission();

  let { data, fetched, loading: dataLoading, getMrtgGraph } = useMrtgGraphSettingHooks();
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({
    upstream: [],
    exchange: [],
  });
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fetched) {
      let defaultForms = {
        ...data.value,
      };
      setForm(defaultForms);
    }
  }, [fetched, data]);

  const onChangeMrtgForm = (key, index, field, value) => {
    let tempForms = { ...form };
    tempForms[key][index][field] = value;
    setForm(tempForms);
  };

  const addGraph = (key) => {
    let tempForms = { ...form };
    tempForms[key] = [...tempForms[key]];
    tempForms[key].push({ name: "", graph_id: "" });
    setForm(tempForms);
  };

  const removeGraph = (key, index) => {
    let tempForms = { ...form };
    tempForms[key] = [...tempForms[key]];

    if (!(tempForms[key].length <= 1)) {
      tempForms[key].splice(index, 1);
      setForm(tempForms);
    }
  };

  const sendData = async (form) => {
    setLoading(true);
    try {
      let response = await updateSettingByKey("mrtg-graph", form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      getMrtgGraph();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = {};
    formData["mrtg-graph"] = form;

    sendData(formData);
  };

  return (
    <Box title="Setting Grafik MRTG" loading={dataLoading || loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <h6 className="pt-2" style={{ fontWeight: "bold" }}>
          Upstream
        </h6>
        {form.upstream.map((graph, index) => (
          <div className="row form-group" key={index}>
            <div className="col">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <label>Nama Grafik</label>
                  <input
                    type="text"
                    className="form-control"
                    value={graph.name}
                    onChange={(event) =>
                      onChangeMrtgForm("upstream", index, "name", event.target.value)
                    }
                  />
                </div>
                <div className="col-12 col-sm">
                  <label>ID Grafik</label>
                  <input
                    type="text"
                    className="form-control"
                    value={graph.graph_id}
                    onChange={(event) =>
                      onChangeMrtgForm("upstream", index, "graph_id", event.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-auto" style={{ paddingTop: "32px" }}>
              <button
                className="btn btn-sm btn-circle btn-dark"
                onClick={() => removeGraph("upstream", index)}
                type="button"
              >
                <i className="fa fa-minus"></i>
              </button>
            </div>
          </div>
        ))}
        <button className="btn btn-sm btn-light" type="button" onClick={() => addGraph("upstream")}>
          <i className="fa fa-plus"></i> Tambah Grafik Upstream
        </button>

        <h6 className="pt-2" style={{ fontWeight: "bold" }}>
          Exchange
        </h6>
        {form.exchange.map((graph, index) => (
          <div className="row form-group" key={index}>
            <div className="col">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <label>Nama Grafik</label>
                  <input
                    type="text"
                    className="form-control"
                    value={graph.name}
                    onChange={(event) =>
                      onChangeMrtgForm("exchange", index, "name", event.target.value)
                    }
                  />
                </div>
                <div className="col-12 col-sm">
                  <label>ID Grafik</label>
                  <input
                    type="text"
                    className="form-control"
                    value={graph.graph_id}
                    onChange={(event) =>
                      onChangeMrtgForm("exchange", index, "graph_id", event.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-auto" style={{ paddingTop: "32px" }}>
              <button
                className="btn btn-sm btn-circle btn-dark"
                onClick={() => removeGraph("exchange", index)}
                type="button"
              >
                <i className="fa fa-minus"></i>
              </button>
            </div>
          </div>
        ))}
        <button className="btn btn-sm btn-light" type="button" onClick={() => addGraph("exchange")}>
          <i className="fa fa-plus"></i> Tambah Grafik Exchange
        </button>
        <div className="form-group d-flex justify-content-end mt-4">
          {hasAccess("update-setting") && (
            <button className="btn btn-primary btn-block">Simpan</button>
          )}
        </div>
      </form>
    </Box>
  );
}
