import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import {
  getOnlineCustomer,
  getStatisticCustomer,
  getStatisticProject,
  getStatisticRegistration,
  getTopLocation,
  getTrafficReports,
  getTrafficStats,
} from "../api/dashboard";

export function useDashboardHooks() {
  const [statistics, setStatistics] = useState([
    {
      status_id: 1,
      status_count: 0,
      status: {
        id: 1,
        name: "ACCEPTED",
        display_name: "REGISTRASI DITERIMA",
        description: "pendaftaran diterima",
      },
    },
    {
      status_id: 2,
      status_count: 0,
      status: {
        id: 2,
        name: "PROCESSED",
        display_name: "PENGECEKAN COVERAGE",
        description: "pendaftaran diproses",
      },
    },
    {
      status_id: 3,
      status_count: 0,
      status: {
        id: 3,
        name: "REJECTED",
        display_name: "REGISTRASI DITOLAK - AREA BELUM TERJANGKAU",
        description: "pendaftaran ditolak dengan alasan area belum tercover",
      },
    },
    {
      status_id: 4,
      status_count: 0,
      status: {
        id: 4,
        name: "WAITING_PAYMENT",
        display_name: "MENUNGGU PEMBAYARAN",
        description: "area tercover, konfirmasi untuk permintaan pembayaran",
      },
    },
    {
      status_id: 5,
      status_count: 0,
      status: {
        id: 5,
        name: "PAYMENT_SUCCESS",
        display_name: "PEMBAYARAN DITERIMA",
        description: "pembayaran berhasil",
      },
    },
    {
      status_id: 6,
      status_count: 0,
      status: {
        id: 6,
        name: "WAITING_INSTALLATION",
        display_name: "PENJADWALAN PEMASANGAN",
        description: "penjadwalan teknisi untuk melakukan pemasangan",
      },
    },
    {
      status_id: 7,
      status_count: 0,
      status: {
        id: 7,
        name: "INSTALLATION_COMPLETE",
        display_name: "TERPASANG",
        description: "proses telah selesai",
      },
    },
  ]);

  const fetchStats = async () => {
    try {
      let { data } = await getStatisticRegistration();
      setStatistics(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return {
    statistics,
  };
}

export function useTrafficReport() {
  const [data, setData] = useState({});
  const [fetched, setFetched] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const fetchTraffic = async () => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      let { data } = await getTrafficReports();
      setData(data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchTraffic();
  }, []);

  return { data, fetched, loading, errorMsg };
}

export function useTrafficStatistic() {
  const [data, setData] = useState({});
  const [fetched, setFetched] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const fetchTraffic = useCallback(async (type = "") => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      let { data } = await getTrafficStats({ type: type });
      setData(data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }

    setLoading(false);
  }, []);

  return { data, fetched, fetchTraffic, loading, errorMsg };
}

export function useOnlineCustomerReport() {
  const [data, setData] = useState([
    { status: "aktif", value: 0 },
    { status: "isolir", value: 0 },
  ]);
  const [fetched, setFetched] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const fetchOnlineCustomer = async () => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      let { data } = await getOnlineCustomer();
      setData(data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchOnlineCustomer();
  }, []);

  return { data, fetched, loading, errorMsg };
}

export function useStatisticCustomer() {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([]);
  const [fetched, setFetched] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const fetchStatCustomer = async () => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      let { data } = await getStatisticCustomer();
      setData(data.data);
      setMeta(data.meta);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchStatCustomer();
  }, []);

  return { data, meta, fetched, loading, errorMsg };
}

export function useTopLocation() {
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const fetchTopLocation = async () => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      let { data } = await getTopLocation();
      setData(data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchTopLocation();
  }, []);

  return { data, fetched, loading, errorMsg };
}

export function useProjectCustomerGraph() {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([]);
  const [fetched, setFetched] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const fetchProjects = async () => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      let { data } = await getStatisticProject();
      setData(data.data);
      setMeta(data.meta);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return { data, meta, fetched, loading, errorMsg };
}
