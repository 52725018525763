import { apiAuth } from "./base";

export function getAllAccounts(params) {
  let api = apiAuth();
  return api.get(`v1/usermobile/account`, { params });
}

export function getAllTopicNotifApps(params) {
  let api = apiAuth();
  return api.get(`v1/usermobile/topicall`, { params });
}

export function createTopicNotifications(data) {
  let api = apiAuth();
  return api.post(`v1/usermobile/createtopic`, data);
}

export function updateTopicByParams(params, data) {
  let api = apiAuth();
  return api.post(`v1/usermobile/updatetopic`, data, {params});
}

export function deleteTopicByParams(params) {
  let api = apiAuth();
  return api.get(`v1/usermobile/deltopic`, { params });
}

export function sendNotifByTopic(data) {
  let api = apiAuth();
  return api.post(`v1/usermobile/pushtopic`, data);
}

export function getHistoriesNotifByTopic(params) {
  let api = apiAuth();
  return api.get(`v1/usermobile/notif/message/all`, { params });
}
