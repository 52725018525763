import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  createTopicNotifications,
  deleteTopicByParams,
  sendNotifByTopic,
  updateTopicByParams,
} from "../../api/manage-app";
import AlertBox from "../AlertBox";
import Toggle from "react-toggle";
import { useAllTopicNotificationsHooks } from "../../utils/usermobile/TopicNotifUtils";

export function ModalCreateTopic({ show, onClose, onSuccess }) {
  const [form, setForm] = useState({ topic_key: "", topic_desc: "", status: 1 });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({ topic_key: "", topic_desc: "", status: 1 });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    createNewTopic(form);
  };

  const createNewTopic = async (formData) => {
    setLoading(true);
    try {
      let response = await createTopicNotifications(formData);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Topik Notifikasi Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Topic Key <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.topic_key}
              onChange={(event) => setForm({ ...form, topic_key: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi Topik <span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control"
              required
              value={form.topic_desc}
              onChange={(event) => setForm({ ...form, topic_desc: event.target.value })}
            />
          </div>
          <div className="row form-group">
            <div className="col-auto">Aktif</div>
            <div className="col-auto">
              <Toggle
                checked={form.status === 1}
                onChange={(event) => {
                  if (event.target.checked) {
                    setForm({ ...form, status: 1 });
                  } else {
                    setForm({ ...form, status: 0 });
                  }
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-plus"></i> Buat Topik Baru
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export function ModalUpdateTopic({ show, onClose, onSuccess, selected }) {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <FormUpdateTopic selected={selected} onSuccess={onSuccess} onClose={() => onClose()} />
    </Modal>
  );
}

function FormUpdateTopic({ selected, onSuccess, onClose }) {
  const [form, setForm] = useState({ topic_key: "", topic_desc: "", status: 1 });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      topic_key: selected.topic_key,
      topic_desc: selected.topic_desc,
      status: selected.status,
    }));
  }, [selected]);

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      onClose();
    }
  };

  const submitData = (event) => {
    event.preventDefault();
    updateTopic(form);
  };

  const updateTopic = async (formData) => {
    setLoading(true);
    try {
      let response = await updateTopicByParams({ topicUUID: selected.id }, formData);
      onSuccess(response.data.message);
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }
  };

  return (
    <form onSubmit={(event) => submitData(event)}>
      <Modal.Header closeButton>
        <Modal.Title>Ubah Topik</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <div className="form-group">
          <label htmlFor="">
            Topic Key <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            required
            disabled
            value={form.topic_key}
            onChange={(event) => setForm({ ...form, topic_key: event.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">
            Deskripsi Topik <span className="text-danger">*</span>
          </label>
          <textarea
            className="form-control"
            required
            value={form.topic_desc}
            onChange={(event) => setForm({ ...form, topic_desc: event.target.value })}
          />
        </div>
        <div className="row form-group">
          <div className="col-auto">Aktif</div>
          <div className="col-auto">
            <Toggle
              checked={form.status === 1}
              onChange={(event) => {
                if (event.target.checked) {
                  setForm({ ...form, status: 1 });
                } else {
                  setForm({ ...form, status: 0 });
                }
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
        <button type="submit" className="btn btn-primary" disabled={loading}>
          <i className="fas fa-plus"></i> Simpan Perubahan Topik
        </button>
      </Modal.Footer>
    </form>
  );
}

export function ModalDeleteTopic({ show, onClose, onSuccess, selected }) {
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      onClose();
    }
  };

  const onDeleteTopic = async () => {
    setLoading(true);
    try {
      let response = await deleteTopicByParams({ topicUUID: selected.id });
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Hapus Topik Notifikasi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert.show && (
          <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
            {alert.message}
            <button
              type="button"
              className="close"
              onClick={() => setAlert({ show: false, message: "", type: "" })}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        <h5 className="text-gray-800">
          Apakah anda yakin akan menghapus topik "{selected.topic_key}"?
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>
          Tidak
        </button>
        <button className="btn btn-danger" onClick={() => onDeleteTopic()} disabled={loading}>
          {loading ? "Menunggu ..." : "Ya"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export function ModalTestNotifByTopic({ show, onClose, onSuccess }) {
  const [form, setForm] = useState({
    topic: "",
    title: "",
    subject: "",
    story_id: "",
    url: "",
  });

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({
      topic: "",
      title: "",
      subject: "",
      story_id: "",
      url: "",
    });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    testNotifBlast(form);
  };

  const testNotifBlast = async (formData) => {
    setLoading(true);
    try {
      await sendNotifByTopic(formData);
      onSuccess("Sukses mengirim notifikasi");
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Test Notifikasi <span className="badge badge-danger">TESTING ONLY</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Topic Key <span className="text-danger">*</span>
            </label>
            <SelectTopic
              type="text"
              required
              value={form.topic}
              onChange={(event) => setForm({ ...form, topic: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Judul <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.title}
              onChange={(event) => setForm({ ...form, title: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.subject}
              onChange={(event) => setForm({ ...form, subject: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Story ID</label>
            <input
              type="text"
              className="form-control"
              value={form.story_id}
              onChange={(event) => setForm({ ...form, story_id: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">URL</label>
            <input
              type="text"
              className="form-control"
              value={form.url}
              onChange={(event) => setForm({ ...form, url: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-bell"></i> Kirim Notifikasi
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export function ModalPushNotifByTopic({ show, onClose, onSuccess }) {
  const [form, setForm] = useState({
    topic: "",
    title: "",
    subject: "",
    story_id: "",
    url: "",
  });

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({
      topic: "",
      title: "",
      subject: "",
      story_id: "",
      url: "",
    });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    testNotifBlast(form);
  };

  const testNotifBlast = async (formData) => {
    setLoading(true);
    try {
      await sendNotifByTopic(formData);
      onSuccess("Sukses mengirim notifikasi");
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Kirim Notifikasi berdasarkan Topik</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Topic Key <span className="text-danger">*</span>
            </label>
            <SelectTopic
              type="text"
              required
              value={form.topic}
              onChange={(event) => setForm({ ...form, topic: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Judul <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.title}
              onChange={(event) => setForm({ ...form, title: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.subject}
              onChange={(event) => setForm({ ...form, subject: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Story ID</label>
            <input
              type="text"
              className="form-control"
              value={form.story_id}
              onChange={(event) => setForm({ ...form, story_id: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">URL</label>
            <input
              type="text"
              className="form-control"
              value={form.url}
              onChange={(event) => setForm({ ...form, url: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-bell"></i> Kirim Notifikasi
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

function SelectTopic({ value, onChange, ...rest }) {
  const { data } = useAllTopicNotificationsHooks();
  return (
    <select className="form-control" value={value} onChange={onChange} {...rest}>
      <option value="">-- Pilih Topik --</option>
      {data.map((topic) => (
        <option value={topic.topic_key} key={topic.id}>
          {topic.topic_key}
        </option>
      ))}
    </select>
  );
}
