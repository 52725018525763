import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import AlertBox from "../../components/AlertBox";
import { useNotificationsHistory } from "../../utils/usermobile/TopicNotifUtils";
import { ModalPushNotifByTopic } from "../../components/usermobile/ModalManageTopic";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import moment from "moment";

export default function HistoryPushNotifPage() {
  let { hasAccess } = useControlPermission();

  const { data, loading, errorMsg, getHistories } = useNotificationsHistory();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [modal, setModal] = useState(null);

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    getHistories();
  };

  return (
    <MainLayout resource_key="app-user">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Riwayat Notifikasi Topik</h1>
            </div>
            <div className="col-auto d-flex" style={{ gap: "4px" }}>
              {hasAccess("create-topic") && (
                <button className="btn btn-primary" onClick={() => setModal("create-notif")}>
                  <i className="fa fa-bell"></i> Push Notifications
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Box title={`Topik Notifikasi`} loading={loading}>
              <AlertBox {...alert} setAlert={setAlert} />

              {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}

              <div style={{ overflowX: "auto" }}>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Tgl. Notifikasi</th>
                      <th>Key</th>
                      <th>Judul</th>
                      <th>Deskripsi</th>
                      <th>Story ID</th>
                      <th>URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length === 0 && (
                      <tr>
                        <td colSpan={4} className="text-center">
                          Tidak ada data
                        </td>
                      </tr>
                    )}
                    {data.map((topic) => (
                      <tr key={topic.id}>
                        <td className="text-nowrap">
                          {moment.unix(topic.createdAt).format("DD MMM YYYY - HH:mm")}
                        </td>
                        <td>{topic.topic_key}</td>
                        <td>{topic.topic_title}</td>
                        <td>{topic.topic_desc}</td>
                        <td>{topic.story_id}</td>
                        <td>{topic.topic_url}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </div>
        </div>
      </div>

      <ModalPushNotifByTopic
        show={modal === "create-notif"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
