import { useState } from "react";
import MainLayout from "../components/layouts/MainLayout";
import BoxPaymentMethodSetting from "../components/setting/BoxPaymentMethodSetting";
import BoxWANotif from "../components/setting/BoxWANotif";
import BoxSettingApps from "../components/setting/BoxSettingApps";
import BoxSettingMrtg from "../components/setting/BoxSettingMrtg";
import BoxSettingModemActivation from "../components/setting/BoxSettingModemActivation";
import BoxSettingRelocate from "../components/setting/BoxSettingRelocate";
import BoxSettingBankMutation from "../components/setting/BoxSettingBankMutation";
import BoxSettingKpiNew from "../components/setting/BoxSettingKpiNew";
import BoxSettingDefaultInventory from "../components/setting/BoxSettingDefaultInventory";
import BoxSettingItemSurvey from "../components/setting/BoxSettingItemSurvey";
import BoxSettingReminderBilling from "../components/setting/BoxSettingReminderBilling";

export default function SettingPage() {
  const [menu, setMenu] = useState("general"); //general, whatsapp-notification, payment-method

  return (
    <MainLayout resource_key="setting">
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Pengaturan</h1>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-2">
            <div className="list-group d-lg-block mb-2">
              <button
                className={`list-group-item list-group-item-action ${
                  menu === "general" ? "active" : ""
                }`}
                onClick={() => setMenu("general")}
              >
                General Settings
              </button>
              <button
                className={`list-group-item list-group-item-action ${
                  menu === "payment-method" ? "active" : ""
                }`}
                onClick={() => setMenu("payment-method")}
              >
                Metode Pembayaran
              </button>
              <button
                className={`list-group-item list-group-item-action ${
                  menu === "mrtg-activation" ? "active" : ""
                }`}
                onClick={() => setMenu("mrtg-activation")}
              >
                MRTG & Aktivasi
              </button>
              <button
                className={`list-group-item list-group-item-action ${
                  menu === "whatsapp-notification" ? "active" : ""
                }`}
                onClick={() => setMenu("whatsapp-notification")}
              >
                Pengingat Pelanggan
              </button>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-10">
            {menu === "general" ? (
              <div className="row">
                <div className="col-12 col-lg-6">
                  <BoxSettingItemSurvey />
                  <BoxSettingDefaultInventory />
                </div>
                <div className="col-12 col-lg-6">
                  <BoxSettingApps />
                  <BoxSettingRelocate />
                  <BoxSettingKpiNew />
                  {/* <Box title="Setting KPI"></Box> */}
                  {/* <Box title="Setting Default Inventory"></Box> */}
                </div>
              </div>
            ) : null}

            {menu === "mrtg-activation" ? (
              <div className="row">
                <div className="col-12 col-lg-6">
                  <BoxSettingMrtg />
                </div>
                <div className="col-12 col-lg-6">
                  <BoxSettingModemActivation />
                </div>
              </div>
            ) : null}

            {menu === "payment-method" ? (
              <div className="row">
                <div className="col-12 col-lg-6">
                  <BoxPaymentMethodSetting />
                </div>
                <div className="col-12 col-lg-6">
                  <BoxSettingBankMutation />
                </div>
              </div>
            ) : null}

            {menu === "whatsapp-notification" ? (
              <div className="row">
                <div className="col-12 col-lg-6">
                  <BoxWANotif />
                </div>
                <div className="col-12 col-lg-6">
                  <BoxSettingReminderBilling />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
