import { useState, useEffect } from "react";
import { useKpiSettingHooks } from "../../utils/SettingUtils";
import Box from "../Box";
import AlertBox from "../AlertBox";
import { updateSettingByKey } from "../../api/setting";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function BoxSettingKpiNew() {
  let { hasAccess } = useControlPermission();

  let { data, fetched, loading: dataLoading, getKpiSetting } = useKpiSettingHooks();
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({});
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fetched) {
      let defaultForms = {
        ...data.value,
      };
      setForm(defaultForms);
    }
  }, [fetched, data]);

  const sendData = async (form) => {
    setLoading(true);
    try {
      let response = await updateSettingByKey("kpi", form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      getKpiSetting();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = {};
    formData["kpi"] = form;

    sendData(formData);
  };

  const changeValueForm = (key, value) => {
    let tempForm = { ...form };
    tempForm[key] = value;
    setForm(tempForm);
  };

  return (
    <Box title="Setting KPI" loading={dataLoading || loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <div className="form-group">
          <label>Total</label>
          <input
            type="text"
            className="form-control"
            value={form.total || 0}
            onChange={(event) => changeValueForm("total", event.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Bonus</label>
          <input
            type="text"
            className="form-control"
            value={form.bonus || 0}
            onChange={(event) => changeValueForm("bonus", event.target.value)}
          />
        </div>
        <div className="form-group d-flex justify-content-end">
          {hasAccess("update-setting") && (
            <button className="btn btn-primary btn-block">Simpan</button>
          )}
        </div>
      </form>
    </Box>
  );
}
