import { useState, useEffect } from "react";
import { useReminderBillingSetting } from "../../utils/SettingUtils";
import Box from "../Box";
import AlertBox from "../AlertBox";
import { updateSettingByKey } from "../../api/setting";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import Toggle from "react-toggle";

export default function BoxSettingReminderBilling() {
  let { hasAccess } = useControlPermission();

  let { data, fetched, loading: dataLoading, getReminderSetting } = useReminderBillingSetting();
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({});
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fetched) {
      let defaultForms = {
        ...data.value,
      };
      setForm(defaultForms);
    }
  }, [fetched, data]);

  const sendData = async (form) => {
    setLoading(true);
    try {
      let response = await updateSettingByKey("overdue-customer-notification", form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      getReminderSetting();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = {};
    formData["overdue-customer-notification"] = form;

    sendData(formData);
  };

  const toggleStatus = (value) => {
    let tempForm = { ...form };
    tempForm.status = value;

    setForm(tempForm);
  };

  return (
    <Box title="Setting Pengingat Jatuh Tempo Pelanggan" loading={dataLoading || loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <div className="form-group row">
          <div className="col-auto">
            <label>Pengingat jatuh tempo pelanggan {form.overdue} hari</label>
          </div>
          <div className="col-auto">
            <Toggle
              checked={form.status === "on"}
              onChange={(event) => {
                if (event.target.checked) {
                  toggleStatus("on");
                } else {
                  toggleStatus("off");
                }
              }}
            />
          </div>
        </div>
        <div className="form-group d-flex justify-content-end">
          {hasAccess("update-setting") && (
            <button className="btn btn-primary btn-block">Simpan</button>
          )}
        </div>
      </form>
    </Box>
  );
}
