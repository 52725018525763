import { useState, useEffect } from "react";
import { useItemSurveySetting } from "../../utils/SettingUtils";
import Box from "../Box";
import AlertBox from "../AlertBox";
import { updateSettingByKey } from "../../api/setting";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function BoxSettingItemSurvey() {
  let { hasAccess } = useControlPermission();

  let { data, fetched, loading: dataLoading, getItemSurveySetting } = useItemSurveySetting();
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fetched) {
      let defaultForms = [...data.value];
      setForm(defaultForms);
    }
  }, [fetched, data]);

  const sendData = async (form) => {
    setLoading(true);
    try {
      let response = await updateSettingByKey("ba-survey-items", form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      getItemSurveySetting();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = {};
    formData["ba-survey-items"] = form;

    sendData(formData);
  };

  const changeFormSurvey = (index, name, value) => {
    let tempForms = [...form];
    tempForms[index][name] = value;
    setForm(tempForms);
  };

  const addDefault = () => {
    let tempForms = [...form];
    tempForms.push({ name: "", price: 0, unit: "" });
    setForm(tempForms);
  };

  const removeDefault = (index) => {
    if (form.length <= 1) {
      return;
    }

    let tempForms = [...form];
    if (!(tempForms.length <= 1)) {
      tempForms.splice(index, 1);
      setForm(tempForms);
    }
  };

  return (
    <Box title="Setting Item Survey" loading={dataLoading || loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        {form.map((defaultItem, index) => (
          <div className="row" key={index}>
            <div className="col">
              <div className="row">
                <div className="col-12 col-md form-group">
                  <label>Default Item #{index + 1}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={defaultItem.name}
                    onChange={(event) => changeFormSurvey(index, "name", event.target.value)}
                  />
                </div>
                <div className="col-12 col-md form-group">
                  <label>Harga per unit #{index + 1}</label>
                  <input
                    type="number"
                    className="form-control"
                    value={defaultItem.price}
                    onChange={(event) =>
                      changeFormSurvey(index, "price", event.target.valueAsNumber)
                    }
                  />
                </div>
                <div className="col-12 col-md form-group">
                  <label>Satuan #{index + 1}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={defaultItem.unit}
                    onChange={(event) => changeFormSurvey(index, "unit", event.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-auto" style={{ paddingTop: "32px" }}>
              <button
                className="btn btn-sm btn-circle btn-dark"
                onClick={() => removeDefault(index)}
                type="button"
              >
                <i className="fa fa-minus"></i>
              </button>
            </div>
          </div>
        ))}
        <div className="form-group mb-4">
          <button className="btn btn-light" type="button" onClick={() => addDefault()}>
            <i className="fa fa-plus"></i> Tambah barang
          </button>
        </div>

        <div className="form-group d-flex justify-content-end">
          {hasAccess("update-setting") && (
            <button className="btn btn-primary btn-block">Simpan</button>
          )}
        </div>
      </form>
    </Box>
  );
}
