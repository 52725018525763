import { useState, useEffect } from "react";
import { useAppVersionSettingHooks } from "../../utils/SettingUtils";
import Box from "../Box";
import AlertBox from "../AlertBox";
import { updateSettingByKey } from "../../api/setting";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

const TYPE_DICTIONARY = {
  user: "MyViberlink (Pengguna)",
  technician: "Teknisi",
};

export default function BoxSettingApps() {
  let { hasAccess } = useControlPermission();

  let { data, fetched, loading: dataLoading, getAppVersion } = useAppVersionSettingHooks();
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({});
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fetched) {
      let defaultForms = {
        ...data.value,
      };
      setForm(defaultForms);
    }
  }, [fetched, data]);

  const sendData = async (form) => {
    setLoading(true);
    try {
      let response = await updateSettingByKey("app-version", form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      getAppVersion();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = {};
    formData["app-version"] = form;

    sendData(formData);
  };

  const changeValueForm = (key, value) => {
    let tempForm = { ...form };
    tempForm[key] = value;
    setForm(tempForm);
  };

  return (
    <Box title="Setting Versi Aplikasi" loading={dataLoading || loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        {Object.keys(form).map((key) => (
          <div className="form-group" key={key}>
            <label>Aplikasi {TYPE_DICTIONARY[key]} Terkini</label>
            <input
              type="text"
              className="form-control"
              value={form[key]}
              onChange={(event) => changeValueForm(key, event.target.value)}
            />
          </div>
        ))}
        <div className="form-group d-flex justify-content-end">
          {hasAccess("update-setting") && (
            <button className="btn btn-primary btn-block">Simpan</button>
          )}
        </div>
      </form>
    </Box>
  );
}
