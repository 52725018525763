import { useState, useEffect } from "react";
import Toggle from "react-toggle";
import { useWaNotifSettingHook } from "../../utils/SettingUtils";
import Box from "../Box";
import AlertBox from "../AlertBox";
import { updateSettingByKey } from "../../api/setting";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function BoxWANotif() {
  let { hasAccess } = useControlPermission();

  let { data, fetched, loading: dataLoading, getWaNotifSettings } = useWaNotifSettingHook();
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [forms, setForms] = useState({});
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fetched) {
      let defaultForms = {
        ...data.value,
      };
      setForms(defaultForms);
    }
  }, [fetched, data]);

  const sendData = async (form) => {
    setLoading(true);
    try {
      let response = await updateSettingByKey("whatsapp-notification", form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      getWaNotifSettings();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = {};
    formData["whatsapp-notification"] = forms;

    sendData(formData);
  };

  const changeValueForm = (key, value) => {
    let tempForm = { ...forms };
    tempForm[key] = value;
    setForms(tempForm);
  };

  return (
    <Box title="Setting Notifikasi WhatsApp" loading={dataLoading || loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: "50%" }}>Whatsapp Key</th>
              <th>On/Off</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(forms).map((key) => (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  <Toggle
                    checked={forms[key] === "on"}
                    onChange={(event) => {
                      changeValueForm(key, event.target.checked ? "on" : "off");
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {hasAccess("update-setting") && (
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary">Simpan Perubahan</button>
          </div>
        )}
      </form>
    </Box>
  );
}
