import { useState, useEffect } from "react";
import {
  getKPISetting,
  getListProvinceStatus,
  getPaymentSetting,
  getPresetSurveyDefaultItem,
  getSettingByKey,
} from "../api/setting";

export function useKpi() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getKpiSetting();
  }, []);

  const getKpiSetting = async () => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getKPISetting();
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getKpiSetting,
  };
}

export function useProvincesCoverageHooks() {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getProvince();
  }, []);

  const getProvince = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getListProvinceStatus();
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getProvince,
  };
}

export function useSurveyDefaultItems() {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getDefaultItem();
  }, []);

  const getDefaultItem = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getPresetSurveyDefaultItem();
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getDefaultItem,
  };
}

export function usePaymentSettingHook() {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getPaymentList();
  }, []);

  const getPaymentList = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getPaymentSetting();
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getPaymentList,
  };
}

export function useWaNotifSettingHook() {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getWaNotifSettings();
  }, []);

  const getWaNotifSettings = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSettingByKey("whatsapp-notification");
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getWaNotifSettings,
  };
}

export function useAppVersionSettingHooks() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getAppVersion();
  }, []);

  const getAppVersion = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSettingByKey("app-version");
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getAppVersion,
  };
}

export function useMrtgGraphSettingHooks() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getMrtgGraph();
  }, []);

  const getMrtgGraph = async () => {
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSettingByKey("mrtg-graph");
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getMrtgGraph,
  };
}

export function useModemActivationSettingHooks() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getActivationSetting();
  }, []);

  const getActivationSetting = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSettingByKey("modem-activation-automation");
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getActivationSetting,
  };
}

export function useRelocateSettingHooks() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getRelocateSetting();
  }, []);

  const getRelocateSetting = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSettingByKey("relocate-fee");
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getRelocateSetting,
  };
}

export function useBankMutationSettingHooks() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getAutoTransferSetting();
  }, []);

  const getAutoTransferSetting = async () => {
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSettingByKey("auto-transfer");
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getAutoTransferSetting,
  };
}

export function useKpiSettingHooks() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getKpiSetting();
  }, []);

  const getKpiSetting = async () => {
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSettingByKey("kpi");
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getKpiSetting,
  };
}

export function useDefaultInventory() {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getInventorySetting();
  }, []);

  const getInventorySetting = async () => {
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSettingByKey("default-inventories");
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getInventorySetting,
  };
}

export function useItemSurveySetting() {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getItemSurveySetting();
  }, []);

  const getItemSurveySetting = async () => {
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSettingByKey("ba-survey-items");
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getItemSurveySetting,
  };
}

export function useReminderBillingSetting() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getReminderSetting();
  }, []);

  const getReminderSetting = async () => {
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSettingByKey("overdue-customer-notification");
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getReminderSetting,
  };
}
