import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import AlertBox from "../../components/AlertBox";
import { useAllTopicNotificationsHooks } from "../../utils/usermobile/TopicNotifUtils";
import {
  ModalCreateTopic,
  ModalDeleteTopic,
  ModalUpdateTopic,
} from "../../components/usermobile/ModalManageTopic";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function AppsNotifTopicPage() {
  let { hasAccess } = useControlPermission();

  const { data, loading, errorMsg, getAllTopic } = useAllTopicNotificationsHooks();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [modal, setModal] = useState(null);
  const [selected, setSelected] = useState({});

  const selectModal = (modalName, selectedData) => {
    setModal(modalName);
    setSelected(selectedData);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    getAllTopic({});
  };

  return (
    <MainLayout resource_key="app-user">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Manajemen Topik Notifikasi</h1>
            </div>
            <div className="col-auto d-flex" style={{ gap: "4px" }}>
              {hasAccess("create-topic") && (
                <button className="btn btn-primary" onClick={() => setModal("create-topic")}>
                  <i className="fa fa-plus"></i> Buat Topik Baru
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Box title={`Topik Notifikasi`} loading={loading}>
              <AlertBox {...alert} setAlert={setAlert} />

              {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}

              <div style={{ overflowX: "auto" }}>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Key</th>
                      <th>Deskripsi Topik</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length === 0 && (
                      <tr>
                        <td colSpan={4} className="text-center">
                          Tidak ada data
                        </td>
                      </tr>
                    )}
                    {data.map((topic) => (
                      <tr key={topic.id}>
                        <td>
                          <div className="d-flex" style={{ gap: "4px" }}>
                            {hasAccess("update-topic") && (
                              <button
                                className="btn btn-sm btn-info"
                                onClick={() => {
                                  selectModal("update-topic", topic);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </button>
                            )}
                            {hasAccess("delete-topic") && (
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                  selectModal("delete-topic", topic);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            )}
                          </div>
                        </td>
                        <td>{topic.topic_key}</td>
                        <td>{topic.topic_desc}</td>
                        <td>{topic.status ? "AKTIF" : "TIDAK AKTIF"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </div>
        </div>
      </div>

      <ModalCreateTopic
        show={modal === "create-topic"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
      <ModalUpdateTopic
        show={modal === "update-topic"}
        selected={selected}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
      <ModalDeleteTopic
        show={modal === "delete-topic"}
        selected={selected}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
