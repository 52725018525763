import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar } from "recharts";
import Box from "../Box";
import { useProjectCustomerGraph } from "../../utils/DashboardHooks";

const COLOR_GRAPH = "#4e73df";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: `1px solid ${COLOR_GRAPH}`,
          borderRadius: "4px",
        }}
      >
        <div className="label fw-bold font-weight-bold">{label}</div>
        <div className="desc">Pelanggan: {`${payload[0].value}`}</div>
      </div>
    );
  }

  return null;
};

export default function BoxProjectGraph() {
  const { data, meta, loading, fetched, errorMsg } = useProjectCustomerGraph();

  let dataParsed = [];

  if (fetched) {
    data.forEach((project, index) => {
      let tempLoc = {
        index: index + 1,
        name: `${project.project || "-"}`,
        pelanggan: project.installed,
      };

      dataParsed.push(tempLoc);
    });
  }

  return (
    <Box title="Statistik Pelanggan" loading={loading}>
      {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}

      {loading === false && (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={730}
            height={250}
            data={dataParsed}
            margin={{ top: 5, right: 30, left: 20, bottom: 100 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              interval={0}
              style={{ fontSize: "12px" }}
              angle={-45} // Rotate labels by 95 degrees
              textAnchor="end" // Align text to the start of the label
              dx={5} // Adjust horizontal position of the labels
              dy={10} // Adjust vertical position of the labels
            />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            {/* <Legend /> */}
            {/* <Bar dataKey="pendaftar" fill="#8884d8" /> */}
            <Bar dataKey="pelanggan" fill={COLOR_GRAPH} />
          </BarChart>
        </ResponsiveContainer>
      )}
      <div style={{ overflowX: "auto" }} className="mt-3">
        <table className="table table-bordered table-sm table-striped table-hover">
          <thead>
            <tr>
              <th>Project</th>
              <th className="text-center">Jumlah Pelanggan</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={2} className="text-center">
                  Tidak ada data yang ditampilkan
                </td>
              </tr>
            )}
            {data.map((project, index) => (
              <tr key={index}>
                <td>{project.project || "-"}</td>
                <td className="text-right">{project.installed}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="font-weight-bold">Total Pelanggan</td>
              <td className="text-right font-weight-bold">{meta.total_installed || 0}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Box>
  );
}
